import axios from "../request/request";

// 轮播图列表
export function bannerList(data) {
  return axios.request({
    url: "/api/api/bannerList",
    method: "post",
    data
  });
}

// 轮播图详情
export function bannerDetail(data) {
  return axios.request({
    url: "/api/api/bannerDetail",
    method: "post",
    data
  });
}

// 投诉建议
export function addComplaint(data) {
  return axios.request({
    url: "/api/api/addComplaint",
    method: "post",
    data
  });
}

// 核心卖点与服务内容
export function pcServerList(data) {
  return axios.request({
    url: "/api/api/pcServerList",
    method: "post",
    data
  });
}

// 品牌与车型
export function getHeaderBrands(data) {
  return axios.request({
    url: "/api/api/getHeaderBrands",
    method: "post",
    data
  });
}

// 4S店筛选条件
export function filterInShop4s(data) {
  return axios.request({
    url: "/api/api/filterInShop4s",
    method: "post",
    data
  });
}

// 4S店列表
export function shop4sList(data) {
  return axios.request({
    url: "/api/api/shop4sList",
    method: "post",
    data
  });
}

// 线下店详情
export function shopDetail(data) {
  return axios.request({
    url: "/api/api/shopDetail",
    method: "post",
    data
  });
}

// 店长推荐
export function shopRecommend(data) {
  return axios.request({
    url: "/api/api/shopRecommend",
    method: "post",
    data
  });
}

// 预约看车
export function addOrderRecForPc(data) {
  return axios.request({
    url: "/api/api/addOrderRecForPc",
    method: "post",
    data
  });
}

// 性价比推荐
export function carIndex(data) {
  return axios.request({
    url: "/api/api/carIndex",
    method: "post",
    data
  });
}

// 获取筛选条件
export function getPcFilterField(data) {
  return axios.request({
    url: "/api/api/getPcFilterField",
    method: "post",
    data
  });
}

// 车辆列表
export function carList(data) {
  return axios.request({
    url: "/api/api/carList",
    method: "post",
    data
  });
}

// // 添加定制要求
// export function addCustomizeRecForPc(data) {
//   return axios.request({
//     url: "/api/api/addCustomizeRecForPc",
//     method: "post",
//     data
//   });
// }

// 车辆详情
export function carDetail(data) {
  return axios.request({
    url: "/api/api/carDetail",
    method: "post",
    data
  });
}

// 获取车辆图片
export function getCarPics(data) {
  return axios.request({
    url: "/api/api/getCarPics",
    method: "post",
    data
  });
}

// 获取汽车参数配置
export function getSpecParams(data) {
  return axios.request({
    url: "/api/api/getSpecParams",
    method: "post",
    data
  });
}

// 维修保养记录
export function getAllUpkeepRec(data) {
  return axios.request({
    url: "/api/api/getAllUpkeepRec",
    method: "post",
    data
  });
}

// 360项车辆检测详情
export function inspection360(data) {
  return axios.request({
    url: "/api/api/inspection360",
    method: "post",
    data
  });
}

// 发送验证码
export function sendSms(data) {
  return axios.request({
    url: "/api/api/sendSms",
    method: "post",
    data
  });
}

// 获取注册小程序码
export function getProgramQrcode(data) {
  return axios.request({
    url: "/api/api/getProgramQrcode",
    method: "post",
    data
  });
}

// 获取登陆小程序码
export function bindUid(data) {
  return axios.request({
    url: "/api/websocket/bindUid",
    method: "post",
    data
  });
}

// 关闭长链接
export function closeClient(data) {
  return axios.request({
    url: "/api/websocket/closeClient",
    method: "post",
    data
  });
}



// 关于我们(官网)
export function aboutus(data) {
  return axios.request({
    url: "/api/api/aboutus",
    method: "post",
    data
  });
}

// 联系我们(官网)
export function contactus(data) {
  return axios.request({
    url: "/api/api/contactus",
    method: "post",
    data
  });
}

// 服务保障(官网)
export function guarantee(data) {
  return axios.request({
    url: "/api/api/guarantee",
    method: "post",
    data
  });
}

// 车辆列表(官网)
export function pcCarList(data) {
  return axios.request({
    url: "/api/api/pcCarList",
    method: "post",
    data
  });
}

// 价格列表（安心无忧维修服务）  选择价格分期（新增功能）
export function repairPrices(data) {
  return axios.request({
    url: "/api/api/repairPrices",
    method: "post",
    data
  });
}


// 悦心服务详情页接口
export function yuexinService(data) {
  return axios.request({
    url: "/api/api/yuexinService",
    method: "get",
    data
  });
}

// 资讯列表接口
export function articleList(data) {
  return axios.request({
    url: "/api/api/articleList",
    method: "get",
    data
  });
}

// 资讯详情接口
export function articleDetail(data) {
  return axios.request({
    url: "/api/api/articleDetail",
    method: "get",
    data
  });
}

// 省级二级联动
export function addressList(data) {
  return axios.request({
    url: "/api/api/addressList",
    method: "get",
    data
  });
}

// 添加定制要求
export function addCustomizeRecForPc(data) {
  return axios.request({
    url: "/api/api/addCustomizeRecForPc",
    method: "post",
    data
  });
}

