// let baseURL = "http://192.168.2.107:8080/";
// let baseURL = "http://192.168.201.20:8080/";
let baseURL = "https://www.yuexinche.com/";

export default {
  // 请求方法同上
  method: "POST", // default
  baseURL: baseURL,
  // socketURL: socketURL,
  // 设置超时时间
  timeout: 50000,
  // 返回数据类型
  responseType: "json",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json"
  }
};
