import axios from "axios";
import defaultConfig from "./config";
/** 所有请求列表完成后才进行消失loader */
const axiosList = [];
class AjaxRequest {
  showLoading() {
    const loading = document.getElementById("loading");
    loading.setAttribute("style", "display: block !important");
  }

  hideLoading() {
    if (axiosList.length == 0) {
      const loading = document.getElementById("loading");
      loading.setAttribute("style", "display: none !important");
    }
  }

  setInterceptor(instance) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        axiosList.push(1);
        this.showLoading();
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use(
      res => {
        axiosList.pop();
        this.hideLoading();
        return Promise.resolve(res.data);
      },
      error => {
        axiosList.pop();
        this.hideLoading();
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    const config = {
      ...options,
      ...defaultConfig
    };
    this.setInterceptor(instance, options.url); // 给这个实例增加拦截功能
    return instance(config);
  }
}

export default new AjaxRequest();
